import { ReactNode } from 'react';

import clsx from 'clsx';

import Modal, { ModalProps } from '../../templates/ModalNew';
import styles from './style.module.scss';

export type DialogType = ModalProps & {
  content?: ReactNode;
  buttonText?: string;
};

export default function Dialog({ content, buttonText = 'OK', actions, testId, ...rest }: DialogType) {
  return (
    <Modal
      {...rest}
      className={clsx(styles.base, rest.className)}
      mobileType={'drawer'}
      actions={actions || [{ label: buttonText, action: 'confirm' }]}
      testId={testId ?? 'confirmation-modal'}
    >
      {content}
    </Modal>
  );
}
