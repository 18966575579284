import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { BookingType, useGetBookingsQuery } from 'redux/apis/OG/booking';
import { PatientType, useGetPatientQuery } from 'redux/apis/OG/patient';
import {
  AdditionalAttributesType,
  AddressType,
  useGetAdditionalAttributesQuery,
  useGetAddressesQuery,
  useGetShippingAddressesQuery,
} from 'redux/apis/OG/user';

import { PaymentMethodType, PaymentOption, useGetProcessorPaymentMethodsQuery } from '../../redux/apis/OG/payment';
import useUser, { UserHookReturnType } from './useUser';

export type IncludeLiteralType =
  | 'addresses'
  | 'bookingAddresses'
  | 'shippingAddresses'
  | 'paymentMethods'
  | 'bookings'
  | 'patient'
  | 'additionalAttributes';

export type addPrefixToObject<T, P extends string> = {
  [K in keyof T as K extends string ? `${P}${K}` : never]: T[K];
};
export type PageHookReturnType = {
  isLoading: boolean;
  isFetching: boolean;
  isUserFetching?: boolean;
  isBookingAddressesFetching?: boolean;
  isShippingAddressesFetching?: boolean;
  isPaymentMethodsFetching?: boolean;
  isBookingsFetching?: boolean;
  isPatientFetching?: boolean;
  isAdditionalAttributesFetching?: boolean;
  queryErrors: Array<any>;
  error?: string | string[] | null;
  addresses?: AddressType[];
  bookingAddresses?: AddressType[];
  shippingAddresses?: AddressType[];
  paymentMethods?: PaymentMethodType[];
  bookings?: BookingType[];
  patient?: PatientType;
  additionalAttributes?: AdditionalAttributesType;
  refetch: (params?: { background?: boolean; only?: (IncludeLiteralType | 'user')[] }) => void;
} & UserHookReturnType;

export type PageHookConfigsType = {
  hold?: boolean;
  extraLoading?: boolean;
  include?: IncludeLiteralType[];
};

export type PageHookInitializerParametersType = Partial<addPrefixToObject<PageHookReturnType, '_'>>;
export type PageHookInitializerFunctionType = (
  params: PageHookInitializerParametersType // TODO: add type for all rest properties prefixed by '_'
) => string | string[] | void | Promise<string | string[] | void>;

const usePage: (initializer?: PageHookInitializerFunctionType, configs?: PageHookConfigsType) => PageHookReturnType = (
  initializer,
  configs
) => {
  const { hold, extraLoading, include } = configs || {};

  const normalizedInclude = useMemo<{ [K: string]: boolean }>(() => {
    return (include || []).reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});
  }, [include]);

  const initInitiated = useRef<boolean>(false);
  const [hasInit, setHasInit] = useState<boolean>(false);
  const [localError, setLocalError] = useState<string | string[]>();
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const {
    isLoading: isUserLoading,
    isFetching: isUserFetching,
    errors: queryErrors,
    refetch: refetchUser,
    ...rest
  } = useUser();

  // Includes
  const {
    data: bookingAddresses = [],
    isLoading: isBookingAddressesLoading,
    isFetching: isBookingAddressesFetching,
    error: bookingAddressesError,
    refetch: refetchBookingAddresses,
  } = useGetAddressesQuery(
    { includeBlastingClients: true },
    {
      skip: (!normalizedInclude.bookingAddresses && !normalizedInclude.addresses) || !rest.isAuthenticated,
    }
  );
  const {
    data: shippingAddresses = [],
    isLoading: isShippingAddressesLoading,
    isFetching: isShippingAddressesFetching,
    error: shippingAddressesError,
    refetch: refetchShippingAddresses,
  } = useGetShippingAddressesQuery(
    { includeBlastingClients: true },
    {
      skip: (!normalizedInclude.shippingAddresses && !normalizedInclude.addresses) || !rest.isAuthenticated,
    }
  );
  const {
    data: paymentMethods,
    isLoading: isPaymentMethodsLoading,
    isFetching: isPaymentMethodsFetching,
    error: paymentMethodsError,
    refetch: refetchPaymentMethods,
  } = useGetProcessorPaymentMethodsQuery(undefined, {
    skip: !normalizedInclude.paymentMethods || !rest.isAuthenticated,
  });
  const {
    data: bookings = [],
    isLoading: isBookingsLoading,
    isFetching: isBookingsFetching,
    error: bookingsError,
    refetch: refetchBookings,
  } = useGetBookingsQuery(
    {
      upcomingOnly: true,
      excludeChair: true,
      includeChildCouples: true,
    },
    {
      skip: !normalizedInclude.bookings || !rest.isAuthenticated || rest?.isTempMember || isUserLoading,
    }
  );
  const {
    data: patient,
    isLoading: isPatientLoading,
    isFetching: isPatientFetching,
    error: patientError,
    refetch: refetchPatient,
  } = useGetPatientQuery(undefined, {
    skip: !normalizedInclude.patient || !rest.isAuthenticated || rest?.isTempMember || isUserLoading,
  });
  const {
    data: additionalAttributes,
    isLoading: isAdditionalAttributesLoading,
    isFetching: isAdditionalAttributesFetching,
    error: additionalAttributesError,
    refetch: refetchAdditionalAttributes,
  } = useGetAdditionalAttributesQuery(undefined, {
    skip: !normalizedInclude.additionalAttributes || !rest.isAuthenticated || rest?.isTempMember || isUserLoading,
  });

  const includesRest = useMemo(() => {
    const v: {
      addresses?: AddressType[];
      bookingAddresses?: AddressType[];
      shippingAddresses?: AddressType[];
      paymentMethods?: PaymentMethodType[];
      patient?: PatientType;
      additionalAttributes?: AdditionalAttributesType;
    } = {
      ...(normalizedInclude.addresses ? { addresses: [...bookingAddresses, ...shippingAddresses] } : {}),
      ...(normalizedInclude.bookingAddresses ? { bookingAddresses } : {}),
      ...(normalizedInclude.shippingAddresses ? { shippingAddresses } : {}),
      ...(normalizedInclude.paymentMethods ? { paymentMethods: paymentMethods?.[PaymentOption.CreditCard] || [] } : {}),
      ...(normalizedInclude.bookings ? { bookings } : {}),
      ...(normalizedInclude.patient ? { patient } : {}),
      ...(normalizedInclude.additionalAttributes ? { additionalAttributes } : {}),
    };
    return v;
  }, [normalizedInclude, bookingAddresses, shippingAddresses, paymentMethods, patient, additionalAttributes]);
  const init = useCallback(async () => {
    if (initInitiated.current === true) return;
    initInitiated.current = true;

    let error: string | string[];
    try {
      const _rest = { ...rest, ...includesRest };
      const _restRenamed = {};
      Object.entries(_rest).forEach(([k, v]) => (_restRenamed[`_${k}`] = v));
      if (initializer) {
        const p: PageHookInitializerParametersType = { _queryErrors: queryErrors, ..._restRenamed };
        const result = await initializer(p);
        if (result) error = result;
      }
    } catch (e) {
      console.error(e);
      error = 'An error occurred';
    }
    if (error) setLocalError(error);
    setTimeout(() => setHasInit(true), 0);
  }, [includesRest]);

  useEffect(() => {
    if (
      hasInit ||
      initInitiated.current === true ||
      isUserLoading ||
      isUserFetching ||
      hold ||
      ((normalizedInclude.addresses || normalizedInclude.bookingAddresses) &&
        (isBookingAddressesLoading || isBookingAddressesFetching)) ||
      ((normalizedInclude.addresses || normalizedInclude.shippingAddresses) &&
        (isShippingAddressesLoading || isShippingAddressesFetching)) ||
      (normalizedInclude.paymentMethods && (isPaymentMethodsLoading || isPaymentMethodsFetching)) ||
      (normalizedInclude.bookings && (isBookingsLoading || isBookingsFetching)) ||
      (normalizedInclude.patient && (isPatientLoading || isPatientFetching)) ||
      (normalizedInclude.additionalAttributes && (isAdditionalAttributesLoading || isAdditionalAttributesFetching))
    )
      return;
    init();
  }, [
    hasInit,
    isUserLoading,
    isUserFetching,
    hold,
    isBookingAddressesLoading,
    isBookingAddressesFetching,
    isShippingAddressesLoading,
    isShippingAddressesFetching,
    isPaymentMethodsLoading,
    isPaymentMethodsFetching,
    isBookingsLoading,
    isBookingsFetching,
    isPatientLoading,
    isPatientFetching,
    isAdditionalAttributesLoading,
    isAdditionalAttributesFetching,
  ]);

  const consolidatedErrors = useMemo(() => {
    const e = [
      ...(queryErrors || []),
      bookingAddressesError,
      shippingAddressesError,
      paymentMethodsError,
      bookingsError,
      patientError,
      additionalAttributesError,
    ].filter((f) => f);
    return e.length ? e : null;
  }, [
    queryErrors,
    bookingAddressesError,
    shippingAddressesError,
    paymentMethodsError,
    bookingsError,
    patientError,
    additionalAttributesError,
  ]);

  const refetch = useCallback(async (options?: { only?: (IncludeLiteralType | 'user')[]; background?: boolean }) => {
    const { only = [], background } = options || {};
    if (!background) setLocalLoading(true);

    try {
      const includes = (segment: IncludeLiteralType | 'user') => !only || !only.length || only.includes(segment);
      const promises = [];
      if (includes('user')) promises.push(refetchUser);
      if (includes('paymentMethods') && normalizedInclude.paymentMethods) promises.push(refetchPaymentMethods);
      if (includes('bookings') && normalizedInclude.bookings) promises.push(refetchBookings);
      if (
        (includes('bookingAddresses') || includes('addresses')) &&
        (normalizedInclude.bookingAddresses || normalizedInclude.addresses)
      ) {
        promises.push(refetchBookingAddresses);
      }
      if (
        (includes('shippingAddresses') || includes('addresses')) &&
        (normalizedInclude.shippingAddresses || normalizedInclude.addresses)
      ) {
        promises.push(refetchShippingAddresses);
      }
      if (includes('patient') && normalizedInclude.patient) promises.push(refetchPatient);
      if (includes('additionalAttributes') && normalizedInclude.businessFlags)
        promises.push(refetchAdditionalAttributes);
      await Promise.allSettled(promises);
    } catch (e) {
      console.error(e);
    }

    setLocalLoading(false);
  }, []);

  return {
    isLoading: !hasInit && (!hasInit || isUserLoading || localLoading || extraLoading),
    isFetching:
      isUserFetching ||
      isBookingAddressesFetching ||
      isShippingAddressesFetching ||
      isPaymentMethodsFetching ||
      isBookingsFetching ||
      isPatientFetching ||
      isAdditionalAttributesFetching,
    isUserFetching,
    isBookingAddressesFetching,
    isShippingAddressesFetching,
    isPaymentMethodsFetching,
    isBookingsFetching,
    isPatientFetching,
    isAdditionalAttributesFetching,
    queryErrors: consolidatedErrors,
    error: localError,
    refetch,
    ...includesRest,
    ...rest,
  };
};

export default usePage;
