import { Component, MouseEvent, ReactNode } from 'react';

import clsx from 'clsx';

import Icon from 'components/common/Icon';

import styles from './style.module.scss';

export type ModalProps = {
  onClose?: (result?: any) => void;
  hidden?: boolean;
  id?: string;
  backdropClose?: boolean;
  backdropClass?: string;
  onBack?: (e: MouseEvent<HTMLDivElement>) => void;
  className?: string;
  children?: ReactNode;
  isClosable?: boolean;
  loading?: boolean;
  loadingError?: ReactNode;
  mobileStyle?: string;
  testId?: string;
};

class Modal extends Component<ModalProps> {
  onBackdropClick = (e: any) => {
    const { backdropClose = false, onClose, isClosable = true } = this.props;
    e.stopPropagation();
    if (backdropClose && onClose && isClosable) {
      onClose();
    }
  };

  onBackButtonEvent = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  componentDidMount() {
    window.onpopstate = this.onBackButtonEvent;
  }

  render() {
    const {
      id,
      hidden,
      children,
      backdropClass = '',
      className = '',
      onClose,
      loadingError = null,
      loading = false,
      isClosable = true,
      onBack = null,
      mobileStyle = null,
      testId = 'modal',
    } = this.props;

    return (
      <form
        onSubmit={(e) => e.preventDefault()}
        className={clsx(styles.modalContainer, backdropClass, { [styles.hidden]: hidden })}
        onMouseDown={(e) => this.onBackdropClick(e)}
        data-testid={`${testId}--backdrop`}>
        <div
          id={id}
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
          className={`${styles.modal} ${className} ${loading ? styles.loading : styles.loaded} ${
            mobileStyle && styles[`mobile-${mobileStyle}`] ? styles[`mobile-${mobileStyle}`] : ''
          }`}
          data-testid={`${testId}`}>
          {loading && <Icon type='fa-circle-o-notch fa-spin' hotspot={false} className={styles.loader} />}
          {!loading && loadingError && (
            <div className={styles.error} data-testid={`${testId}--loading-error-div`}>
              {loadingError}
            </div>
          )}
          {!loading && !loadingError && (
            <>
              {onBack && (
                <div onClick={onBack} className={styles.back}>
                  <Icon type='arrow-left' data-testid={`${testId}--back-icon`} />
                </div>
              )}
              {isClosable && (
                <Icon
                  type='times'
                  className={`${styles.close}`}
                  onClick={() => onClose()}
                  title='close'
                  data-testid={`${testId}--close-icon`}
                />
              )}
              {children}
            </>
          )}
        </div>
      </form>
    );
  }
}

export default Modal;
