import { FC, useCallback } from 'react';

import clsx from 'clsx';

import { useModals } from '@zeel-dev/zeel-ui';

import { useGetPageBySlugQuery } from '../../../redux/apis/Wordpress';
import { helpers } from '../../../utils';
import ModalDialog from '../../modals/items/Dialog';
import { IconSvg, Link } from '../index';
import styles from './style.module.scss';

export type HsaFsaEligibleProps = {
  className?: string;
  outlined?: boolean;
  outlinedWhite?: boolean;
};

export const HsaFsaEligible: FC<HsaFsaEligibleProps> = ({ className, outlined, outlinedWhite }) => {
  const { openModal } = useModals();
  const { data, isLoading } = useGetPageBySlugQuery('hsa-fsa');

  const handlePillClick = useCallback(() => {
    const { title, description, web_icon, learn_more_link } = (data?.elements as any)?.hsafsa || {};

    openModal({
      element: (
        <ModalDialog
          className={styles.dialog}
          loading={isLoading}
          title={title}
          icon={helpers.isLinkFull(web_icon) ? undefined : web_icon}
          iconColor={'midnight'}
          imageUrl={helpers.isLinkFull(web_icon) ? web_icon : undefined}
          actions={[]}
          headerClassName={styles.modalHeader}
          description={
            <>
              <span style={{ whiteSpace: 'pre-line' }}>{description}</span>{' '}
              <Link href={learn_more_link} target='_blank' className={styles.link}>
                Learn more
              </Link>
            </>
          }
        />
      ),
    });
  }, [data, isLoading]);

  return (
    <div
      className={clsx(
        styles.base,
        {
          [styles.outlined]: outlined,
          [styles.outlinedWhite]: outlinedWhite,
        },
        className
      )}
      onClick={handlePillClick}
    >
      <IconSvg name='medical-cross' size={16} />
      <span className={styles.label}>HSA/FSA Eligible</span>
    </div>
  );
};
