import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { authApi, getTokenCookie, setTokenCookie } from 'redux/apis/OG/auth';
import { USER_HAS_LOGGED_IN_MATCHER } from 'redux/apis/OG/utils';

import { useSliceWrapper } from '@zeel-dev/zeel-ui';

export interface State {
  authenticated: boolean;
  loggingOut: boolean;
}

const initialState: State = {
  authenticated: !!getTokenCookie(),
  loggingOut: false,
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    handleWebviewAuth: (state, action: PayloadAction<string>) => {
      setTokenCookie(action.payload);
      state.authenticated = true;
      state.loggingOut = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.logoutAndRefresh.matchPending, (state) => {
      state.loggingOut = true;
      state.authenticated = false;
    });
    builder.addMatcher(authApi.endpoints.logoutAndRefresh.matchFulfilled, (state) => {
      state.loggingOut = false;
      state.authenticated = false;
    });
    builder.addMatcher(authApi.endpoints.logout.matchPending, (state) => {
      state.loggingOut = true;
      state.authenticated = false;
    });
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      state.loggingOut = false;
      state.authenticated = false;
    });
    builder.addMatcher(USER_HAS_LOGGED_IN_MATCHER, (state, { payload }) => {
      if (payload.token) {
        state.authenticated = true;
      }
    });
  },
});

export const authReducer = authSlice.reducer;
export const isLoggingOut = (state) => state[authSlice.name].loggingOut;
export const isAuthenticated = (state) => {
  return state[authSlice.name].authenticated;
};

/**------------------------------------------------------------------**
 *                              Hook                                  *
 **------------------------------------------------------------------**/

export const useAuthSliceWrapper = () => {
  return useSliceWrapper(authSlice, { isAuthenticated });
};
