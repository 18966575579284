import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import clsx from 'clsx';

import { routes } from 'utils';
import { useUser } from 'utils/hooks';

import { useRollbar } from '@zeel-dev/zeel-ui';
import { Image, Link } from 'components/common';
import BaseLayout from 'components/layouts/BaseLayout';

import styles from './style.module.scss';

export type Props = {
  error?: any;
};
const SomethingWentWrong: FC<Props> = ({ error }) => {
  const { isLoading, user, isAuthenticated } = useUser();
  const location = useLocation();
  const rollbar = useRollbar();

  useEffect(() => {
    if (!isLoading)
      rollbar.error(`Error occurred in route: ${location.pathname}`, error, { memberId: user?.id, isAuthenticated });
  }, [isLoading]);

  return (
    <BaseLayout
      tags={{
        title: 'Something Went Wrong',
      }}
    >
      <div className={clsx(styles.glContainer, styles.base)}>
        <h1>Oops, something went wrong.</h1>
        <p>
          Sorry for the inconvenience.
          <br />
          <Link className={styles.glBoldLink} onClick={() => (window.location = routes.index({ host: true }))}>
            Return to our Homepage
          </Link>
        </p>
        <div className={styles.imageContainer}>
          <Image className={styles.image} src='greenery/leaf-full.png' />
        </div>
      </div>
    </BaseLayout>
  );
};

export default SomethingWentWrong;
