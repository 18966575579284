import { Component, ReactNode } from 'react';
import { HostedField } from 'react-braintree-fields';

import clsx from 'clsx';

import { ThemeContext } from 'utils/theme-context';

import { SkeletonCover } from '@zeel-dev/zeel-ui';

import Icon from '../../Icon';
import styles from './style.module.scss';

type Props = {
  label?: ReactNode;
  type: string;
  prefill?: string;
  placeholder?: string;
  readOnly?: boolean;
  onChange?: (v: any, e: any) => void;
  onError?: (e?: any) => void;
  error?: string;
  meta?: any;
  name?: string;
  theme?: string;
  testId?: string;
  hideValidState?: boolean;
};

export default class InputBraintree extends Component<Props> {
  onValidityChange = (type, e) => {
    const { isEmpty, isValid /* , isPotentiallyValid, isFocused */ } = e;
    const { onChange, onError } = this.props;

    let error = null;

    if (!isValid) {
      error = 'Field is not valid';

      if (isEmpty) {
        error = 'Field can not be empty';
      }
    }

    if (onChange) {
      onChange('', error);
    }
    if (error && onError) {
      onError(error);
    }
  };

  render() {
    const {
      label,
      type,
      error,
      readOnly,
      placeholder = '',
      prefill = '',
      meta = {},
      testId,
      hideValidState,
    } = this.props;
    const theme = this.props.theme || this.context;
    const hasBeenTouched = meta.touched;
    const isValid = !error;
    const errorMessage = hasBeenTouched && error;

    return (
      <SkeletonCover>
        <div
          className={clsx(
            styles.inputPayment,
            { [styles.invalid]: hasBeenTouched && !isValid },
            { [styles.valid]: hasBeenTouched && isValid && !hideValidState },
            { [styles.readOnly]: readOnly },
            styles[`theme-${theme}`]
          )}
        >
          {label && (
            <label className={styles.label} data-testid={`${testId ?? `input-braintree`}--label`}>
              {label}
            </label>
          )}
          <div className={styles.wrapper}>
            <HostedField
              className={styles.hostedField}
              prefill={prefill}
              placeholder={placeholder}
              type={type}
              onValidityChange={(e) => this.onValidityChange(type, e)}
              data-testid={testId ?? `input-braintree`}
            />
            <div className={styles.icons}>
              {hasBeenTouched && isValid && !hideValidState && (
                <Icon className={`${styles.icon} ${styles.validationIcon}`} type='checkmark-circle' />
              )}
              {hasBeenTouched && !isValid && (
                <Icon className={`${styles.icon} ${styles.validationIcon}`} type='exclamation-circle' />
              )}
            </div>
          </div>
          <div className={styles.validationText}>{errorMessage || ''}</div>
        </div>
      </SkeletonCover>
    );
  }
}
InputBraintree.contextType = ThemeContext;
