import { FC } from 'react';

import clsx from 'clsx';

import { withErrorBoundary } from 'utils/hoc';

import { Image } from 'components/common';
import BaseLayout from 'components/layouts/BaseLayout';

import styles from '../style.module.scss';

const SomethingWentWrong: FC = () => {
  return (
    <BaseLayout
      tags={{
        title: 'Something went wrong',
      }}
    >
      <div className={clsx(styles.glContainer, styles.base)}>
        <h1>Oops, something went wrong.</h1>
        <p>Please try again later.</p>
        <div className={styles.imageContainer}>
          <Image className={styles.image} src='greenery/leaf-full.png' />
        </div>
      </div>
    </BaseLayout>
  );
};

export default withErrorBoundary(SomethingWentWrong);
