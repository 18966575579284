import { FC, useCallback } from 'react';

import { useForm, usePage } from 'utils/hooks';

import { useSendResetPasswordMutation } from 'redux/apis/OG/user';

import { useModals } from '@zeel-dev/zeel-ui';
import { Button, FieldRow, Icon, Input } from 'components/common';
import Modal, { ModalProps } from 'components/modals/templates/Modal';

import ModalForgotPasswordSent from '../ForgotPasswordSent';
import styles from './style.module.scss';

type Props = ModalProps & {
  emailFunnel?: boolean;
  email?: string;
};

const ForgotPassword: FC<Props> = ({ emailFunnel, email, ...rest }) => {
  const form = useForm();
  const { openModal, closeAllModals } = useModals();
  const [sendResetPassword] = useSendResetPasswordMutation();

  const { isLoading } = usePage(() => {
    if (email) form.setValue('email', email);
  });

  const resetPassword = useCallback(async () => {
    const _email = form.getValue('email');

    try {
      await sendResetPassword(_email).unwrap();
      closeAllModals();
      openModal({
        element: <ModalForgotPasswordSent />,
      });
    } catch (errors: any) {
      if (errors?.email) {
        form.setError('email', errors.email);
      }
    }
  }, [emailFunnel, form]);

  return (
    <Modal {...rest} loading={isLoading} testId='forgot-password-modal'>
      {!isLoading && (
        <div className={styles.insideModal}>
          <div className={styles.modalHeader}>
            <Icon type='exclamation-circle' className={styles.icon} />
            <div className={styles.title} data-testid='forgot-password-modal--title'>
              Forgot Password
            </div>
            <div className={styles.subtitle} data-testid='forgot-password-modal--description'>
              Enter your email and we&apos;ll send a link allowing
              <br />
              you to reset/set your password
            </div>
          </div>

          <div className={styles.modalBodyWrapper}>
            <div className={`${styles.modalBody} ${styles.expandTop}`}>
              <FieldRow>
                <Input
                  {...form.getProps('email')}
                  type='email'
                  label='Email Address'
                  autoComplete='email username'
                  testId='forgot-password-modal--email-input'
                />
              </FieldRow>
            </div>
          </div>

          <div className={styles.modalFooter}>
            <Button
              className={styles.button}
              type='primary'
              disabled={!form.isValid()}
              onClick={() => resetPassword()}
              testId='forgot-password-modal--main-button'
            >
              Reset Password
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ForgotPassword;
