import { FC, useCallback } from 'react';

import ModalContinueCustomizing from 'screens/PersonalWellness/modals/ContinueCustomizing';

import { useModals } from '@zeel-dev/zeel-ui';
import { Button, Icon } from 'components/common';
import Modal, { ModalProps } from 'components/modals/templates/Modal';

import ModalForgotPassword from '../ForgotPassword';
import styles from './style.module.scss';

type Props = ModalProps & {
  email?: string;
  emailFunnel?: boolean;
};

const ForgotPasswordSent: FC<Props> = ({ email, emailFunnel, onClose, ...rest }) => {
  const { openModal } = useModals();
  const handleOnClose = useCallback(() => {
    if (emailFunnel) {
      openModal({
        element: <ModalContinueCustomizing signInForm {...{ email }} />,
      });
    } else onClose();
  }, [email, emailFunnel]);

  const onBack = () => {
    openModal({ element: <ModalForgotPassword /> });
  };

  return (
    <Modal {...rest} onClose={handleOnClose} onBack={onBack} testId='forgot-password-sent-modal'>
      <div className={styles.insideModal}>
        <div className={styles.modalHeader}>
          <Icon type='exclamation-circle' className={styles.icon} />
          <div className={styles.title}>Please Check Your Email</div>
        </div>

        <div className={styles.modalBodyWrapper}>
          <div className={`${styles.modalBody} ${styles.justify} ${styles.expandDown}`}>
            We&apos;re sending you an email with instructions on how to reset your password. If you don&apos;t receive
            the instructions within a minute or two try re-sending the instructions.
            <br />
            If you are using Gmail, please make sure to check the &quot;promotions&quot; tab as the password reset email
            often goes into that tab. You should also check your spam folders if you can&apos;t find the email.
          </div>
        </div>

        <div className={styles.modalFooter}>
          <Button
            className={styles.button}
            type='primary'
            onClick={handleOnClose}
            testId='forgot-password-sent-modal--main-button'
          >
            Back to Sign In
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotPasswordSent;
