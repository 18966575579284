export const BASE_URL: string = process.env.REACT_APP_OG_API;
export const API_KEY: string = process.env.REACT_APP_OG_API_KEY;
export const TAGS: { [key: string]: string } = {
  PAYMENT: 'payment',
  ADDRESS: 'address',
  USER: 'user',
  USER_MEMBERSHIP: 'user-membership',
  PATIENT: 'patient',
  BOOKING: 'booking',
  STORE_PRODUCT: 'store-product',
  STORE_ORDER: 'store-order',
  PROVIDER: 'provider',
  HELP: 'help',
  TOKEN: 'token',
  CREDIT: 'credit',
  INSTANT_BOOK_TIME: 'instant-book-time',
  PAYMENT_OPTION: 'payment-option',
  PAYMENT_METHOD: 'payment-method',
};
