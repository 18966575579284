import { GenericResponse, NGApi, TAGS } from '..';
import { QuestionnaireResponseType, QuestionnaireType, TimeContextType } from './types';

export const evaluationApi = NGApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Get the questionnaire template by id
     */
    getQuestionnaire: builder.query<QuestionnaireType, string>({
      query: (id) => {
        return {
          url: `/evaluation/v1/questionnaire/${id}`,
          method: 'GET',
        };
      },
      providesTags: (result) => {
        return [...(result ? [{ type: TAGS.QUESTIONNAIRE, id: result.id }] : []), TAGS.QUESTIONNAIRE];
      },
    }),

    /**
     * Fetch specific questionnaire response related to an appointment
     */
    getQuestionnaireResponse: builder.query<
      QuestionnaireResponseType,
      {
        questionnaireId: string;
        ogAppointmentId?: string;
        ngAppointmentId?: string;
      }
    >({
      query: ({ questionnaireId, ngAppointmentId, ogAppointmentId }) => {
        const params: { ng_appointment_id?: string; og_appointment_id?: string } = {};
        if (ngAppointmentId) params.ng_appointment_id = ngAppointmentId;
        if (ogAppointmentId) params.og_appointment_id = ogAppointmentId;
        return {
          url: `/evaluation/v1/user/survey/${questionnaireId}`,
          method: 'GET',
          params,
        };
      },
      providesTags: (result) => {
        return [...(result ? [{ type: TAGS.QUESTIONNAIRE_RESPONSE, id: result.id }] : []), TAGS.QUESTIONNAIRE_RESPONSE];
      },
    }),

    /**
     * Update an existing questionnaire-response object using ID. Data
     * should be formatted exactly the same as the original object (fhir)
     */
    updateQuestionnaireResponse: builder.mutation<
      GenericResponse,
      {
        id: string;
        questionnaireResponse?: Partial<QuestionnaireResponseType>;
      }
    >({
      query: ({ id, questionnaireResponse }) => {
        return {
          url: `/evaluation/v1/questionnaire-response/${id}`,
          method: 'PATCH',
          body: questionnaireResponse,
        };
      },
      invalidatesTags: (result, error, arg) => [
        TAGS.QUESTIONNAIRE_RESPONSE,
        { type: TAGS.QUESTIONNAIRE_RESPONSE, id: arg.id },
      ],
    }),

    /**
     * Fetch specific questionnaire response related to an appointment
     */
    getAppointmentScalesByContext: builder.query<
      {
        questionnaires: QuestionnaireType[];
        additional_questions: string[];
        initial_questions: { question: string; answer: string }[];
      },
      { ogAppointmentId: string; context: TimeContextType }
    >({
      query: ({ ogAppointmentId, context }) => {
        return {
          url: `/evaluation/v1/appointment-og/${ogAppointmentId}/scales?time_context=${context}`,
          method: 'GET',
        };
      },
      providesTags: (result) => {
        return result
          ? [...(result.questionnaires || []).map(({ id }) => ({ type: TAGS.QUESTIONNAIRE, id })), TAGS.QUESTIONNAIRE]
          : [TAGS.QUESTIONNAIRE];
      },
    }),
  }),
});

export const {
  // Queries
  useGetQuestionnaireQuery,
  useGetQuestionnaireResponseQuery,
  useGetAppointmentScalesByContextQuery,
  // Lazy
  useLazyGetQuestionnaireQuery,
  useLazyGetQuestionnaireResponseQuery,
  useLazyGetAppointmentScalesByContextQuery,
  // Mutations
  useUpdateQuestionnaireResponseMutation,
} = evaluationApi;
