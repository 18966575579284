import set from 'lodash/set';

import { registerMapping } from '@zeel-dev/zeel-ui';

import * as T from './types';

export const WPCustomElementsMapping = registerMapping<T.WPSourceCustomElementsType, T.WPCustomElementsType>(
  'WPCustomElements',
  {
    operations: [],
    derivedProperties: [
      [
        'meta',
        'hero',
        'ceo_quote',
        'value_propositions',
        'interior_subnav',
        'ceo_highlight',
        'leadership',
        'investors',
        'card',
        'video_section',
        'podcast',
        'recent_media',
        'details',
        'valuePropList',
        'banner',
        'banner_v2',
        'price',
        'location',
        'providers',
        'titleWithContent',
        'titleWithContent2',
        'faqs',
        'titleWithBackground',
        'benefits',
        'physicalTherapyLeadForm',
        'hsa_fsa_reimbursement',
      ],
      ({ input }) => {
        const o = { ...(input || {}) }; // for immutability
        Object.keys(o)
          ?.filter((k) => k.includes('.'))
          ?.forEach((key: string) => {
            set(o, key?.split('.'), input[key]);
            delete o[key];
          });
        return o as T.WPCustomElementsType;
      },
    ],
    decoders: {
      source: T.WPSourceCustomElementsDecoder,
      target: T.WPCustomElementsDecoder,
    },
  }
);

export const WPElementMapping = registerMapping<T.WPSourceElementType, T.WPElementType>('WPElement', {
  operations: [
    ['copy', { sourceProperties: ['id', 'date', 'modified', 'slug', 'link', 'type'] }],
    ['compose', { sourceProperty: 'elements', mapper: 'WPCustomElements' }],
  ],
  derivedProperties: [
    ['titleHtml', 'contentHtml', 'summaryHtml'],
    ({ input }) => {
      return {
        titleHtml: input?.title?.rendered,
        contentHtml: input?.content?.rendered,
        summaryHtml: input?.excerpt?.rendered,
      };
    },
  ],
  decoders: {
    source: T.WPSourceElementDecoder,
    target: T.WPElementDecoder,
  },
});
