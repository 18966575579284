import { withErrorBoundary as _withErrorBoundary } from 'react-error-boundary';

import SomethingWentWrong from 'components/SomethingWentWrong';

const withErrorBoundary = (C) =>
  _withErrorBoundary(C, {
    fallbackRender: ({ error: e }) => <SomethingWentWrong error={e} />,
  });

export default withErrorBoundary;
