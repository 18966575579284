import { useMemo } from 'react';

import { getCookie } from '../helpers';

export enum WebviewSourceEnum {
  Android = 'android',
  IOS = 'ios',
}

export const WEBVIEW_SOURCE_COOKIE_NAME = 'webview-source';

export type UiHookReturnType = {
  webviewSource: WebviewSourceEnum | undefined;
  fromMobile: boolean;
};

const useUi: () => UiHookReturnType = () => {
  const webviewSource = getCookie(WEBVIEW_SOURCE_COOKIE_NAME);
  const fromMobile = useMemo(
    () => [WebviewSourceEnum.Android, WebviewSourceEnum.IOS].includes(webviewSource),
    [webviewSource]
  );
  return {
    webviewSource,
    fromMobile,
  };
};

export default useUi;
