import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';

import {
  CaretBoldDownIcon,
  DuplicateCopyBoldIcon,
  EditPencilBoldIcon,
  VaccineInjectionSyringeBoldIcon,
} from '@zeel-dev/zeel-ui';

import { copyToClipboard } from '../../utils/helpers';
import styles from './style.module.scss';

enum DebuggerPosition {
  Left = 'left',
  Right = 'right',
}

type DebuggerProps = {
  position?: string; // string (not enum) since it comes from url search params
};

const Debugger: FC<DebuggerProps> = ({ position: _position }) => {
  const [toggled, setToggled] = useState(false);
  const [, setTick] = useState(0);
  const [copied, setCopied] = useState(false);
  const [startLine, setStartLine] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setTick((prev) => prev + 1), 1000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  const clear = useCallback(() => {
    setStartLine(((console as any).logs || []).length);
  }, []);

  const copy = useCallback(async () => {
    let text = '';
    ((console as any).logs || []).forEach((log: any[]) => {
      log.forEach((item: any) => {
        text += `${item}\n`;
      });
    });
    await copyToClipboard(text);
    setCopied(true);
  }, []);

  const position = useMemo(() => {
    if (Object.values(DebuggerPosition).includes(_position as DebuggerPosition)) return _position;
    return DebuggerPosition.Right;
  }, [_position]);

  return (
    <>
      <div className={clsx(styles.container, { [styles['container--toggled']]: toggled })}>
        <div className={styles.inner}>
          {((console as any).logs || []).slice(startLine).map((log: any[], index: number) => (
            <div
              key={index}
              className={clsx(styles.log, {
                [styles['log--error']]: log?.[0] === 'error',
                [styles['log--warning']]: log?.[0] === 'warning',
              })}
            >
              {log.map((item, i) => (
                <span key={i} className={styles.item}>
                  {JSON.stringify(item, null, 2)}
                </span>
              ))}
              <div className={styles.index}>{index + 1}</div>
            </div>
          ))}
        </div>
        <EditPencilBoldIcon className={styles.clearIcon} size={24} rotate={'105deg'} onClick={() => clear()} />
        <DuplicateCopyBoldIcon
          className={clsx(styles.copyIcon, { [styles['copyIcon--copied']]: copied })}
          size={24}
          onClick={() => copy()}
        />
        <CaretBoldDownIcon className={styles.closeIcon} size={30} onClick={() => setToggled(false)} />
      </div>
      <div
        className={clsx(styles.button, styles[`button--position-${position}`], {
          [styles['button--hidden']]: toggled,
        })}
        onClick={() => setToggled(!toggled)}
      >
        <VaccineInjectionSyringeBoldIcon size={30} />
      </div>
    </>
  );
};

export default Debugger;
