import cloneDeep from 'lodash/cloneDeep';

import { ArticleType, CategoryType } from './types';

/**
 * Returns the sub-categories and articles directly under acategory.
 * Will attach the full slug to the articles based on the category context
 **/
export const getContentOfCategory = (
  params: { categories: CategoryType[]; articles: ArticleType[] },
  categoryId: string
): { articles: (ArticleType & { fullSlug: string })[]; categories: CategoryType[] } => {
  const { categories = [], articles = [] } = params || {};
  const category = categories.find((c) => c.id === categoryId);
  return {
    categories: categories.filter((c) => c.parent === categoryId),
    articles: articles
      .filter((a) => !!a.categories.find((c) => c.id === categoryId))
      .map((a) => {
        return {
          ...a,
          fullSlug: `${category.fullSlug}/${a.slug}`,
        };
      }),
  };
};

/**
 * Returns all articles under a specific category
 */
export const getAllArticlesUnderCategory = (
  params: { categories: CategoryType[]; articles: ArticleType[] },
  categoryId: string
): ArticleType[] => {
  const { categories = [], articles = [] } = params || {};
  const category = categories.find((c) => c.id === categoryId);
  if (!category) return;

  const _articles = cloneDeep(articles);
  const filteredArticles = _articles.filter((article) => {
    const allParentCategories = article.categories.map((c) => c.id); // getting all direct categories an article belongs in
    return !!allParentCategories.find((parentCategoryId) => {
      const cat = categories.find((c) => c.id === parentCategoryId);
      if (!cat) return false;
      const articleFullSlug = `${cat.fullSlug}/${article.slug}`;
      const matchingCategory = cat.parentList.find((pc) => pc.id === categoryId); // checking if at least 1 of the direct categories of the article has the categoryId (from arg) in its list of parent categories
      article.fullSlug = articleFullSlug;
      return !!matchingCategory;
    });
  });
  return filteredArticles;
};

/**
 * Gets the content of the help-center's home page
 */
export const getHomeContent = (
  params: { categories: CategoryType[]; articles: ArticleType[] },
  brand
): Array<CategoryType & { allArticles: ArticleType[]; articles: ArticleType[] }> => {
  const { categories = [] } = params || {};
  const mainCategories = categories.filter((c) => {
    return c.brand === brand && c.root;
  });

  return mainCategories.map((mainCategory) => {
    return {
      ...mainCategory,
      allArticles: getAllArticlesUnderCategory(params, mainCategory.id),
      articles: getContentOfCategory(params, mainCategory.id).articles,
    };
  });
};

/**
 * Gets all details for a full slug
 */
export const getFullSlugDetails = (
  params: { categories: CategoryType[]; articles: ArticleType[] },
  fullSlug
): {
  type: 'category' | 'article';
  item: CategoryType | (ArticleType & { fullSlug: string });
  content: { articles: (ArticleType & { fullSlug: string })[]; categories: CategoryType[] };
  brand: string;
  mainCategories: CategoryType[];
  breadcrumb: { title: string; fullSlug: string }[];
} => {
  const { categories = [], articles = [] } = params || {};
  const slugList = fullSlug.split('/');

  const matchingCategory = categories.find((c) => c.fullSlug === fullSlug);
  if (matchingCategory) {
    // the slug matches a category
    return {
      type: 'category',
      item: matchingCategory,
      content: getContentOfCategory(params, matchingCategory.id),
      brand: matchingCategory.brand,
      mainCategories: categories.filter((c) => c.root && c.brand === matchingCategory.brand),
      breadcrumb: matchingCategory.parentList,
    };
  } else {
    // slug did not match a category, might be an article, or an invalid slug
    const lastSlug = slugList[slugList.length - 1];
    const secondToLastSlug = slugList[slugList.length - 2];
    if (!secondToLastSlug) return null; // slug is invalid as there is only an article slug

    const matchingArticle = articles.find((c) => c.slug === lastSlug);
    const matchingParentCategory = categories.find((c) => c.slug === secondToLastSlug);
    if (!matchingArticle || !matchingParentCategory) return null;

    return {
      type: 'article',
      item: {
        ...matchingArticle,
        fullSlug: `${matchingParentCategory.fullSlug}/${matchingArticle.slug}`,
      },
      content: null,
      brand: matchingParentCategory.brand,
      mainCategories: categories.filter((c) => c.root && c.brand === matchingParentCategory.brand),
      breadcrumb: matchingParentCategory.parentList,
    };
  }
};

export const isChatOpen = () => {
  const kustomerDiv = (document.getElementsByClassName('kustomer') || [])?.[0];
  if (!kustomerDiv) return false;
  return kustomerDiv.hasChildNodes();
};

export const openChat = () => {
  try {
    (window as any).Kustomer?.open?.();
  } catch (error) {
    alert(JSON.stringify(error));
    console.error(error);
  }
};
