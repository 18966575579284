import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { QuestionnaireResponseType, QuestionnaireType, TimeContext, TimeContextType } from 'redux/apis/NG/evaluation';
import { authSlice } from 'redux/features/auth';
import { RootState } from 'redux/store';

import { useSliceWrapper } from '@zeel-dev/zeel-ui';

import persistReducer from '../../../persistReducer';

/**------------------------------------------------------------------**
 *                              Types                                 *
 **------------------------------------------------------------------**/

export enum ErrorsEnum {
  Invalid = 'invalid',
  Patient = 'patient',
  Appointment = 'appointment',
  AccountType = 'account-type',
  Questionnaire = 'questionnaire',
}

export const errors = {
  [ErrorsEnum.Invalid]: {
    title: 'Sorry, we couldn’t find the form.',
    description:
      "It’s possible that the link is broken or missing some information. Please chat with us, and we'll get this resolved.",
  },
  [ErrorsEnum.Patient]: {
    title: 'Sorry, we couldn’t match your patient information to this account.',
    description:
      'It’s possible that you used a different account for this appointment. Please try to sign in again with your patient account. If you’re still having trouble, we’re here to help – please chat with us and we’ll get this sorted.',
    canSignOut: true,
  },
  [ErrorsEnum.Appointment]: {
    title: 'Sorry, we couldn’t find your appointment.',
    description:
      'It’s possible that this appointment was changed or canceled after this link was created. If this doesn’t sound right, or you want more information, we’re here to help.',
  },
  [ErrorsEnum.AccountType]: {
    title: 'Sorry, we couldn’t match your patient information to this account.',
    description:
      "It’s possible that you used a different account for this appointment. Please try to sign in again with your patient account. If you still have trouble after that, please chat with us, and we'll get this resolved.",
    canSignOut: true,
  },
  [ErrorsEnum.Questionnaire]: {
    title: 'Sorry, we’re having trouble loading this form.',
    description:
      "It’s possible that this form has been changed or no longer exists. Please chat with us, and we'll get this resolved.",
  },
};

export enum Steps {
  Account = 'account',
  Details = 'details',
  Questionnaire = 'questionnaire',
  Notes = 'notes',
  ReviewProvider = 'review-provider',
  Feedback = 'feedback',
}

export type ParametersType = {
  type?: TimeContextType;
  appointmentId?: string;
};

export type State = {
  parameters?: ParametersType;
  questionnaireTemplates?: QuestionnaireType[];
  questionnaireAnswers?: QuestionnaireResponseType[];
};

/**------------------------------------------------------------------**
 *                           Initial State                            *
 **------------------------------------------------------------------**/

const initialState: State = {
  parameters: null,
  questionnaireTemplates: null,
  questionnaireAnswers: null,
};

/**------------------------------------------------------------------**
 *                              Slice                                 *
 **------------------------------------------------------------------**/

export const vaEvaluationFlowSlice = createSlice({
  name: 'vaEvaluationFlowSlice',
  initialState,
  reducers: {
    parametersProvided(state, action: PayloadAction<ParametersType>) {
      const parameters = action.payload;
      if (
        parameters &&
        state.parameters &&
        (parameters.appointmentId !== state.parameters.appointmentId || parameters.type !== state.parameters.type)
      ) {
        state.questionnaireTemplates = null;
        state.questionnaireAnswers = null;
      }
      state.parameters = action.payload;
    },
    parametersCleared(state) {
      state.parameters = null;
      state.questionnaireTemplates = null;
      state.questionnaireAnswers = null;
    },
    questionnaireTemplatesUpdated(state, action: PayloadAction<QuestionnaireType[]>) {
      state.questionnaireTemplates = action.payload;
    },
    questionnaireAnswersUpdated(state, action: PayloadAction<QuestionnaireResponseType[]>) {
      state.questionnaireAnswers = action.payload;
    },
    questionnaireAnswerUpdatedAtIndex(state, action: PayloadAction<{ index: number | string; answer: any }>) {
      const { index, answer } = action.payload || {};
      const updatedQuestionnaireAnswers = [...state.questionnaireAnswers];
      updatedQuestionnaireAnswers[index] = {
        ...(updatedQuestionnaireAnswers[index] || {}),
        ...(answer || {}),
      };
      state.questionnaireAnswers = updatedQuestionnaireAnswers;
    },
    flowResetted() {
      return { ...initialState };
    },
    flowCompleted() {
      return { ...initialState };
    },
  },
});

export const vaEvaluationFlowReducer = persistReducer<State>(vaEvaluationFlowSlice);

/**------------------------------------------------------------------**
 *                            Selectors                               *
 **------------------------------------------------------------------**/

const getSliceState = (state: RootState): State => state[vaEvaluationFlowSlice.name] as State;

export const vaEvaluationFlowSelectors = {
  selectParameters: (state) => getSliceState(state).parameters,
  selectIsPreTreatment: (state) => getSliceState(state).parameters?.type === TimeContext.Pre,
  selectIsPostTreatment: (state) => getSliceState(state).parameters?.type === TimeContext.Post,
  selectQuestionnaireTemplates: (state) => {
    const { questionnaireTemplates } = getSliceState(state);
    return (
      questionnaireTemplates?.map((questionnaire) => ({
        id: questionnaire.id,
        items: questionnaire?.item || [],
      })) || []
    );
  },

  selectQuestionnaireAnswers: (state) => {
    const { questionnaireAnswers } = getSliceState(state);
    return (
      questionnaireAnswers?.map((answer) => ({
        id: answer?.id,
        items: answer?.item || [],
      })) || []
    );
  },
  /**
   * Should be memoized, but will wait for redux-toolkit v2.0.0
   * for selectors defined within the slice itself
   **/
  selectIsStepAvailable: (state, step: Steps) => {
    const { parameters, questionnaireTemplates, questionnaireAnswers } = getSliceState(state);
    const isAuthenticated = state[authSlice.name].authenticated as boolean;
    const isAccountAvailable = true;
    !parameters?.appointmentId || ![TimeContext.Pre, TimeContext.Post].includes(parameters?.type);
    const isDetailsAvailable =
      isAccountAvailable &&
      isAuthenticated &&
      parameters?.appointmentId &&
      [TimeContext.Pre, TimeContext.Post].includes(parameters?.type);
    const isQuestionnaireAvailable = isDetailsAvailable && questionnaireTemplates && questionnaireAnswers;
    const isNotesAvailable = isQuestionnaireAvailable;
    const isReviewProviderAvailable = isQuestionnaireAvailable;
    const isFeedbackAvailable = isReviewProviderAvailable;

    return {
      [Steps.Account]: isAccountAvailable,
      [Steps.Details]: isDetailsAvailable,
      [Steps.Questionnaire]: isQuestionnaireAvailable,
      [Steps.Notes]: isNotesAvailable,
      [Steps.ReviewProvider]: isReviewProviderAvailable,
      [Steps.Feedback]: isFeedbackAvailable,
    }[step];
  },
};

/**------------------------------------------------------------------**
 *                              Hook                                  *
 **------------------------------------------------------------------**/

export const useVaEvaluationFlowSlice = () => {
  return useSliceWrapper(vaEvaluationFlowSlice, vaEvaluationFlowSelectors);
};
