import * as D from 'io-ts/Decoder';

// GLOBAL

export enum FhirItemTypes {
  GROUP = 'group',
  CHOICE = 'choice',
  OPEN_CHOICE = 'open-choice',
  TEXT = 'text',
  BOOLEAN = 'boolean',
  // Unused/unsupported for now
  DISPLAY = 'display',
  DECIMAL = 'decimal',
  INTEGER = 'integer',
  DATE = 'date',
  DATE_TIME = 'dateTime',
  TIME = 'time',
  STRING = 'string',
  URL = 'url',
  ATTACHMENT = 'attachment',
  REFERENCE = 'reference',
  QUANTITY = 'quantity',
}

export enum TimeContext {
  Pre = 'pre',
  Post = 'post',
}

export const TimeContextDecoder = D.literal(TimeContext.Pre, TimeContext.Post);
export type TimeContextType = D.TypeOf<typeof TimeContextDecoder>;

// Questionnaire
export const QuestionnaireItemDecoder = D.partial({
  answer: D.nullable(
    D.array(
      D.partial({
        value_integer: D.nullable(D.number),
        value_string: D.nullable(D.string),
        value_boolean: D.nullable(D.boolean),
        value_date: D.nullable(D.string), // e.g 2018, 1973-06, or 1905-08-23
        value_time: D.nullable(D.string), // e.g 16:04:00
        value_coding: D.nullable(
          D.partial({
            code: D.nullable(D.string), // e.g '399150003'
            display: D.nullable(D.string), // e.g 'PCR test for SARS-CoV-2'
            system: D.nullable(D.string), // e.g 'http://snomed.info/sct'
          })
        ),
        value_reference: D.nullable(
          D.partial({
            reference: D.nullable(D.string), // e.g 'Patient/2045'
            type: D.nullable(D.string), // e.g Patient
          })
        ),
      })
    )
  ),
  answer_option: D.nullable(
    D.array(
      D.partial({
        value_integer: D.nullable(D.number),
        value_string: D.nullable(D.string),
        value_boolean: D.nullable(D.boolean),
        value_date: D.nullable(D.string), // e.g 2018, 1973-06, or 1905-08-23
        value_time: D.nullable(D.string), // e.g 16:04:00
        value_coding: D.nullable(
          D.partial({
            code: D.nullable(D.string), // e.g '399150003'
            display: D.nullable(D.string), // e.g 'PCR test for SARS-CoV-2'
            system: D.nullable(D.string), // e.g 'http://snomed.info/sct'
          })
        ),
        value_reference: D.nullable(
          D.partial({
            reference: D.nullable(D.string), // e.g 'Patient/2045'
            type: D.nullable(D.string), // e.g Patient
          })
        ),
      })
    )
  ),
  code: D.nullable(
    D.array(
      D.partial({
        code: D.nullable(D.string), // e.g '399150003'
        display: D.nullable(D.string), // e.g 'PCR test for SARS-CoV-2'
        system: D.nullable(D.string), // e.g 'http://snomed.info/sct'
      })
    )
  ),
  enable_behavior: D.nullable(D.literal('all', 'any')), // e.g 'all'
  enable_when: D.nullable(
    D.array(
      D.partial({
        answer_string: D.nullable(D.string), // e.g '10'
        answer_boolean: D.nullable(D.boolean), // e.g true
        answer_date: D.nullable(D.string), // e.g 2021-06-16
        answer_date_time: D.nullable(D.string), // e.g 2021-06-16T12:00:00+00:00
        answer_time: D.nullable(D.string), // e.g 12:00:00.000
        answer_decimal: D.nullable(D.number), // e.g 10
        answer_integer: D.nullable(D.number), // e.g 10
        answer_quantity: D.nullable(
          D.partial({
            code: D.nullable(D.string), // e.g '399150003'
            system: D.nullable(D.string), // e.g 'http://snomed.info/sct'
            unit: D.nullable(D.string), // e.g 'mmol/L'
            value: D.nullable(D.number), // e.g 10
            comparator: D.nullable(D.literal('<', '<=', '>', '>=')), // e.g '<'
          })
        ),
        answer_reference: D.nullable(
          D.partial({
            reference: D.nullable(D.string), // e.g 'Patient/2045'
            type: D.nullable(D.string), // e.g Patient
          })
        ),
        answer_code: D.nullable(
          D.partial({
            code: D.nullable(D.string), // e.g '399150003'
            display: D.nullable(D.string), // e.g 'PCR test for SARS-CoV-2'
            system: D.nullable(D.string), // e.g 'http://snomed.info/sct'
          })
        ),
        operator: D.nullable(D.literal('exists', '=', '!=', '>', '<', '>=', '<=')), // e.g '='
        question: D.nullable(D.string), // e.g '10'
      })
    )
  ),
  link_id: D.nullable(D.string), // e.g 'q1.0'
  max_length: D.nullable(D.number), // e.g 140
  prefix: D.nullable(D.string), // e.g '1(a)'
  repeats: D.nullable(D.boolean), // e.g true
  required: D.nullable(D.boolean), // e.g true
  text: D.nullable(D.string), // e.g 'What country do you live in?'
  type: D.nullable(
    D.literal(
      FhirItemTypes.GROUP,
      FhirItemTypes.CHOICE,
      FhirItemTypes.OPEN_CHOICE,
      FhirItemTypes.TEXT,
      FhirItemTypes.BOOLEAN,
      FhirItemTypes.DISPLAY,
      FhirItemTypes.DECIMAL,
      FhirItemTypes.INTEGER,
      FhirItemTypes.DATE,
      FhirItemTypes.DATE_TIME,
      FhirItemTypes.TIME,
      FhirItemTypes.STRING,
      FhirItemTypes.URL,
      FhirItemTypes.ATTACHMENT,
      FhirItemTypes.REFERENCE,
      FhirItemTypes.QUANTITY
    )
  ), // e.g 'choice'
  item: D.nullable(D.UnknownArray), // recursive issue
  _is_slider: D.nullable(D.boolean), // convenience property
  _is_multiple: D.nullable(D.boolean), // convenience property
  _is_group: D.nullable(D.boolean),
});
export type QuestionnaireItemType = D.TypeOf<typeof QuestionnaireItemDecoder>;

export const QuestionnaireResponseDecoder = D.partial({
  author: D.nullable(
    D.partial({
      reference: D.nullable(D.string), // e.g Patient/2045
      type: D.nullable(D.string), // e.g Patient
    })
  ),
  authored: D.nullable(D.string), // e.g 2021-03-01T23:48:54
  based_on: D.nullable(D.array(D.string)), // e.g ['1', '3']
  encounter: D.nullable(D.string), // e.g '1'
  extension: D.nullable(
    D.array(
      D.partial({
        url: D.nullable(D.string), // e.g http://zeel.com/fhir/extension-value
        value_boolean: D.nullable(D.boolean), // e.g true
      })
    )
  ),
  first_submitted_date: D.nullable(D.string), // e.g 2023-10-04T12:00:00.12345Z
  id: D.string, // e.g '10'
  identifier: D.nullable(
    D.partial({
      system: D.nullable(D.string), // e.g http://www.zeel.com/fhir/member_id
      value: D.nullable(D.string), // e.g '234A567'
      type: D.nullable(
        D.partial({
          text: D.nullable(D.string), // e.g 'covid19:pcr test'
          coding: D.nullable(
            D.array(
              D.partial({
                code: D.nullable(D.string), // e.g '399150003'
                display: D.nullable(D.string), // e.g 'PCR test for SARS-CoV-2'
                system: D.nullable(D.string), // e.g 'http://snomed.info/sct'
              })
            )
          ),
        })
      ),
    })
  ),
  item: D.nullable(D.array(QuestionnaireItemDecoder)),
  last_submitted_date: D.nullable(D.string), // e.g 2023-10-04T12:00:00.12345Z
  part_of: D.nullable(
    D.array(
      D.partial({
        reference: D.nullable(D.string), // e.g Patient/2045
        type: D.nullable(D.string), // e.g Patient
      })
    )
  ),
  questionnaire: D.nullable(D.string), // e.g https://api.zeel-tech.com/medical_massage/v1/questionnaire/1337
  source: D.nullable(
    D.partial({
      reference: D.nullable(D.string), // e.g Patient/2045
      type: D.nullable(D.string), // e.g Patient
    })
  ),
  status: D.nullable(D.literal('entered-in-error', 'final', 'preliminary', 'stopped', 'submitted')), // e.g 'active'
  subject: D.nullable(
    D.partial({
      reference: D.nullable(D.string), // e.g Patient/2045
      type: D.nullable(D.string), // e.g Patient
    })
  ),
  submitted_by: D.nullable(D.string), // e.g '2045'
  submitted_date: D.nullable(D.string), // e.g 2023-10-04T12:00:00.12345Z
});
export type QuestionnaireResponseType = D.TypeOf<typeof QuestionnaireResponseDecoder>;

export const QuestionnaireDecoder = D.partial({
  approval_date: D.nullable(D.string), // e.g 2021-03-01T23:48:54
  date: D.nullable(D.string), // e.g 2023-10-04T12:00:00.12345Z
  description: D.nullable(D.string), // e.g 'This is a questionnaire for covid19'
  extension: D.nullable(D.UnknownArray),
  id: D.string, // e.g '10'
  identifier: D.nullable(
    D.array(
      D.partial({
        system: D.nullable(D.string), // e.g http://www.zeel.com/fhir/member_id
        value: D.nullable(D.string), // e.g '234A567'
        type: D.nullable(
          D.partial({
            text: D.nullable(D.string), // e.g 'covid19:pcr test'
            coding: D.nullable(
              D.array(
                D.partial({
                  code: D.nullable(D.string), // e.g '399150003'
                  display: D.nullable(D.string), // e.g 'PCR test for SARS-CoV-2'
                  system: D.nullable(D.string), // e.g 'http://snomed.info/sct'
                })
              )
            ),
          })
        ),
      })
    )
  ),
  items: D.nullable(D.array(QuestionnaireItemDecoder)),
  item: D.nullable(D.array(QuestionnaireItemDecoder)),
  last_review_date: D.nullable(D.string), // e.g 2021-03-01T23:48:54
  publisher: D.nullable(D.string), // e.g 'Zeel'
  purpose: D.nullable(D.string), // e.g 'The purpose of this questionnaire is to assess the risk of covid19'
  status: D.nullable(D.literal('draft', 'active', 'retired', 'unknown')), // e.g 'active'
  title: D.nullable(D.string), // e.g 'Clinical Assessment - Subjective - Pain (DVPRS)'
  version: D.nullable(D.string), // e.g 'v1.2'

  questionnaire_response: D.nullable(QuestionnaireResponseDecoder),
});
export type QuestionnaireType = D.TypeOf<typeof QuestionnaireDecoder>;
