import { FC } from 'react';

import clsx from 'clsx';

import { routes } from 'utils';
import { ThemeProvider, themes } from 'utils/theme-context';

import { IconSvg, Image, Link, SocialIcon } from 'components/common';

import { useGetPageBySlugQuery } from '../../../redux/apis/Wordpress';
import { HsaFsaEligible } from '../../common/HsaFsaEligible';
import { FooterProps } from './Footer.types';
import styles from './style.module.scss';

const Footer: FC<FooterProps> = ({ va, health, testId }) => {
  const { data: wordpressData } = useGetPageBySlugQuery('hsa-fsa');
  const { learn_more_link } = (wordpressData?.elements as any)?.hsafsa || {};

  const CITIES = [
    { name: 'Atlanta', href: routes.IN_HOME_MASSAGE_ATLANTA() },
    { name: 'Austin', href: routes.IN_HOME_MASSAGE_AUSTIN() },
    { name: 'Chicago', href: routes.IN_HOME_MASSAGE_CHICAGO() },
    { name: 'Dallas/Fort Worth', href: routes.IN_HOME_MASSAGE_DALLAS_FORTH_WORTH() },
    { name: 'Denver', href: routes.IN_HOME_MASSAGE_DENVER() },
    { name: 'Houston', href: routes.IN_HOME_MASSAGE_HOUSTON() },
    { name: 'Los Angeles', href: routes.IN_HOME_MASSAGE_LOS_ANGELES() },
    { name: 'Miami', href: routes.IN_HOME_MASSAGE_MIAMI() },
    { name: 'New York City', href: routes.IN_HOME_MASSAGE_NYC() },
    { name: 'Phoenix', href: routes.IN_HOME_MASSAGE_PHOENIX() }, // is it supposed to be Portland?
    { name: 'San Francisco', href: routes.IN_HOME_MASSAGE_SAN_FRANCISCO() },
    { name: 'More', href: routes.MASSAGES_NEAR_ME() },
  ];

  const CHAIR_CITIES = [
    { name: 'Austin', href: routes.CHAIR_MASSAGE_AUSTIN({ host: true }) },
    { name: 'Chicago', href: routes.CHAIR_MASSAGE_CHICAGO({ host: true }) },
    { name: 'Dallas', href: routes.CHAIR_MASSAGE_DALLAS({ host: true }) },
    { name: 'Denver', href: routes.CHAIR_MASSAGE_DENVER({ host: true }) },
    { name: 'Houston', href: routes.CHAIR_MASSAGE_HOUSTON({ host: true }) },
    { name: 'Los Angeles', href: routes.CHAIR_MASSAGE_LOS_ANGELES({ host: true }) },
    { name: 'Miami', href: routes.CHAIR_MASSAGE_MIAMI({ host: true }) },
    { name: 'New York City', href: routes.CHAIR_MASSAGE_NYC({ host: true }) },
    { name: 'Portland', href: routes.CHAIR_MASSAGE_PORTLAND({ host: true }) },
    { name: 'San Francisco', href: routes.CHAIR_MASSAGE_SAN_FRANCISCO({ host: true }) },
    { name: 'Seattle', href: routes.CHAIR_MASSAGE_SEATTLE({ host: true }) },
    { name: 'More', href: routes.CHAIR_MASSAGE.BOOK() },
  ];

  if (va) {
    return (
      <div id='footer' className={styles.vaFooter} data-testid={testId ?? `footer`}>
        <ThemeProvider value={themes.medical}>
          <div className={clsx(styles.glContainer, styles.vaContainer)}>
            <div className={styles.imageCopySection}>
              <div className={styles.logosContainer}>
                <Image className={styles.logo} src='logo.svg' />
                <Image className={styles.logoVet} src='logos/proud-veteran.png' />
              </div>
              <p>
                ©{new Date().getFullYear()} Zeel Networks Inc. All rights reserved. Zeel delivers medical services
                through its affiliate ZP Medical Services PC, a licensed medical practice. This content is solely for
                informational purposes and does not substitute for diagnostic or medical advice. You should always
                consult your physician if you have questions or concerns of a personal, medical nature. Please read our{' '}
                <Link href={routes.TERMS_OF_USE({ host: true })}>Terms of Use</Link> and{' '}
                <Link href={routes.PRIVACY_POLICY({ host: true })}>Privacy Policy</Link> for more information.
              </p>
            </div>
          </div>
        </ThemeProvider>
      </div>
    );
  } else {
    return (
      <div id='footer' className={styles.footer} data-testid={testId ?? `footer`}>
        <div className={styles.glContainerSmall}>
          <ThemeProvider value={themes.primary}>
            <div className={styles.links}>
              <ul>
                <li>
                  <h3 className={styles.menuTitle}>Individuals</h3>
                </li>
                <li>
                  <p className={styles.headerLink}>In-Home Services</p>
                </li>
                <li>
                  <Link className={styles.link} href={routes.IN_HOME_MASSAGE()}>
                    <IconSvg name='massage-hands' />
                    <p>Massage</p>
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.VA_MICROSITE({ host: true })}>
                    <IconSvg name='military' />
                    <p>Massage for Veterans</p>
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.MASSAGES_NEAR_ME({ host: true })}>
                    Massage Pricing & Locations
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.MEMBERSHIP.index()}>
                    Massage Membership
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.MASSAGE_GIFT_CERTIFICATE({ host: true })}>
                    Zeel Gifts
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.ESTORE.index()}>
                    Zeel Store
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.TRUST_AND_SAFETY.CONSUMER()}>
                    Trust & Safety
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={learn_more_link} target='_blank'>
                    HSA/FSA Payment
                  </Link>
                </li>
              </ul>

              <ThemeProvider value={themes.corporate}>
                <ul className={styles.businessList}>
                  <li>
                    <h3 className={styles.menuTitle}>Businesses</h3>
                  </li>
                  <li>
                    <Link className={clsx(styles.link, styles['link-business'])} href={routes.HEALTHCARE()}>
                      Solutions for Healthcare Partners
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={clsx(styles.link, styles['link-business'])}
                      href={routes.CORPORATE_WELLNESS.index()}
                    >
                      Workplace Wellness
                    </Link>
                  </li>
                  <li>
                    <Link className={clsx(styles.link, styles['link-business'])} href={routes.CHAIR_MASSAGE.BOOK()}>
                      Book a Chair Massage Event
                    </Link>
                  </li>
                  <li>
                    <Link className={clsx(styles.link, styles['link-business'])} href={routes.SPA({ host: true })}>
                      Zeel Spa Staffing
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={clsx(styles.link, styles['link-business'])}
                      href={routes.TRUST_AND_SAFETY.BUSINESS()}
                    >
                      Trust & Safety
                    </Link>
                  </li>
                </ul>
              </ThemeProvider>

              <ul>
                <li>
                  <h3 className={styles.menuTitle}>Providers</h3>
                </li>
                <li>
                  <Link className={styles.link} href={routes.PROVIDER.index()}>
                    Work With Zeel
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.PROVIDER.APPLICATION()}>
                    Apply Now
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.TRUST_AND_SAFETY.PROVIDER()}>
                    Trust & Safety
                  </Link>
                </li>
              </ul>

              <div className={clsx(styles.glVisibleOnMobile, styles.separator)} />

              <ul>
                <li>
                  <h3 className={styles.menuTitle}>About</h3>
                </li>
                <li>
                  <Link className={styles.link} href={routes.ABOUT_US()}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.CAREER()}>
                    Careers
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.BLOG({ host: true })}>
                    Blog
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.PRESS_MEDIA_NEWS()}>
                    Press, Media, and News
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.PRESS_RELEASES.index()}>
                    Press Releases
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.SITEMAP({ host: true })}>
                    Site Map
                  </Link>
                </li>
                <li>
                  <Link className={styles.link} href={routes.ACCESSIBILITY()}>
                    Accessibility Statement
                  </Link>
                </li>
              </ul>

              {/* Local Landing Pages */}

              <div>
                <h3 className={styles.menuTitle}>In-Home Massage Locations</h3>
                <ul className={styles.localList}>
                  {CITIES.map(({ name, href }) => {
                    return (
                      <li key={name}>
                        <Link className={styles.link} href={href}>
                          {name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>

                <h3 className={styles.menuTitle}>Chair Massage Locations</h3>

                <ul className={styles.localList}>
                  {CHAIR_CITIES.map(({ name, href }) => {
                    return (
                      <li key={name}>
                        <Link className={styles.link} href={href}>
                          {name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </ThemeProvider>
          <div className={styles.footerBottom}>
            <div className={styles.leftSide}>
              <div id='box-apps' className={styles.appsBox}>
                <Link href={routes.STORE_APPLE()} className={`${styles.appBox} ${styles.storeApple}`} />
                <Link href={routes.STORE_GOOGLE()} className={`${styles.appBox} ${styles.storeGoogle}`} />
              </div>
            </div>
            <div className={styles.rightSide}>
              <ul id='box-social' className={styles.socialBox} role='list'>
                <li>
                  <SocialIcon type='twitter' />
                </li>
                <li>
                  <SocialIcon type='facebook' />
                </li>
                <li>
                  <SocialIcon type='instagram' />
                </li>
                <li>
                  <SocialIcon type='yelp' />
                </li>
              </ul>
              <div id='box-copyright' className={styles.copyrightBox}>
                {!health && (
                  <>
                    Copyright {new Date().getFullYear()}, Zeel Networks, Inc. The contents of the Zeel web site are for
                    informational purposes only. None of the information on the site should be construed or used as
                    professional medical advice or consultation. Please read our{' '}
                    <Link href={routes.TERMS_OF_USE({ host: true })}>Terms of Use</Link> and{' '}
                    <Link href={routes.PRIVACY_POLICY({ host: true })}>Privacy Policy</Link> for more information.
                  </>
                )}
                {health && (
                  <>
                    ©{new Date().getFullYear()} Zeel Networks Inc. All rights reserved. Zeel Health is the dba for ZP
                    Medical Services PC, a licensed medical practice providing medical services. This content is solely
                    for informational purposes and does not substitute for diagnostic or medical advice. You should
                    always consult your physician if you have questions or concerns of a personal, medical nature.
                    Please read our <Link href={routes.TERMS_OF_USE({ host: true })}>terms of use</Link> and{' '}
                    <Link href={routes.PRIVACY_POLICY({ host: true })}>privacy policy</Link> for more information.
                  </>
                )}
              </div>
              <HsaFsaEligible className={styles.hsafsa} outlined />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Footer;
