import orderBy from 'lodash/orderBy';
import moment from 'moment/moment';

import { PaymentMethodType } from './types';

/**
 * Gets the default payment method from the list of payment methods
 * @param paymentMethods[]
 */
export function getDefaultPaymentMethod<T>(paymentMethods: T[] = []): T {
  return paymentMethods.find((i: any) => i.default) as T;
}

/**
 * Sorts the payment methods by status and date edited
 * @param paymentMethods[]
 */
export function sortPaymentMethods(paymentMethods: PaymentMethodType[]): PaymentMethodType[] {
  return orderBy(
    paymentMethods,
    (p) =>
      `${p.statusLabel === 'expired' || p.statusLabel === 'invalid' ? '0' : '1'}-${moment(p.dateEdited).format(
        'YYYYMMDD'
      )}`,
    ['desc']
  );
}
