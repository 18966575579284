import isNil from 'lodash/isNil';

import { GenericResponse, OGApi, TAGS } from '..';
import { BookingConfigurationType } from '../booking';
import { PaymentMethodMapping } from './mapping';
import {
  PaymentMethodType,
  PaymentOption,
  PaymentOptionsResponse,
  PaymentProcessor,
  PaymentUsedFor,
  SourcePaymentMethodType,
} from './types';
import { sortPaymentMethods } from './utils';

export const paymentApi = OGApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Retrieves the payment options for the user.
     */
    getPaymentOptions: builder.query<
      PaymentOptionsResponse,
      {
        usedFor: PaymentUsedFor;
        bookingData?: BookingConfigurationType;
        amount?: number;
      }
    >({
      query: ({ usedFor, bookingData, amount }) => {
        const extraPayload = { [PaymentUsedFor.InHomeBooking]: bookingData }[usedFor] || {};
        return {
          url: '/api/v1/member/card/options',
          method: 'POST',
          body: {
            _data: '',
            used_for: usedFor,
            extra_payload: extraPayload,
            amount: amount || 0,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { options: PaymentOptionsResponse }) => {
        return responseData.options;
      },
      providesTags: [TAGS.PAYMENT_OPTION],
    }),

    /**
     * Retrieves the client token for the specified processor.
     */
    getClientProcessorToken: builder.query<string, PaymentProcessor>({
      query: (processor) => {
        return {
          url: `/api/v1/member/card/client_token/${processor}`,
          method: 'POST',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { client_token: string }) => {
        return responseData.client_token;
      },
    }),

    /**
     * Creates a payment method for the specified processor.
     */
    createProcessorPaymentMethod: builder.mutation<
      PaymentMethodType,
      Pick<PaymentMethodType, 'label'> & {
        initialAgreedAmount?: number;
        processor: PaymentProcessor;
      } & ({ nonce: string; transactionId?: undefined } | { transactionId: string; nonce?: undefined })
    >({
      queryFn: async (
        { label, nonce, transactionId, initialAgreedAmount, processor },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        const result = await fetchWithBQ({
          url: `/api/v1/member/card/create/${processor}`,
          method: 'POST',
          body: {
            _data: '',
            profile_name: label,
            ...(nonce ? { payment_method_nonce: nonce } : {}),
            ...(transactionId ? { fortis_tx_id: transactionId } : {}),
            ...(!isNil(initialAgreedAmount) ? { initial_agreed_amount: initialAgreedAmount } : {}),
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        });
        if (result.error) return { error: result.error };
        // Fetch payment method (since api only returns id)
        const fetchPaymentResult = await fetchWithBQ({
          url: `/api/v1/member/card/${result.data?.id}`,
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        });
        const fullPayment = fetchPaymentResult.data?.card;
        return fullPayment
          ? {
              data: PaymentMethodMapping.apply(fullPayment),
            }
          : { error: fetchPaymentResult.error };
      },
      invalidatesTags: [TAGS.PAYMENT_METHOD],
    }),

    /**
     * Updates a payment method for the processor
     * that this payment method belongs to.
     */
    updateProcessorPaymentMethod: builder.mutation<
      PaymentMethodType,
      Pick<PaymentMethodType, 'id' | 'label'> & {
        nonce: string;
      }
    >({
      queryFn: async ({ id, label, nonce }, _queryApi, _extraOptions, fetchWithBQ) => {
        const result = await fetchWithBQ({
          url: `/api/v1/member/card/update/${id}`,
          method: 'POST',
          body: {
            _data: '',
            profile_name: label,
            payment_method_nonce: nonce,
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        });
        if (result.error) return { error: result.error };
        // Fetch payment method (since api only returns id)
        const fetchPaymentResult = await fetchWithBQ({
          url: `/api/v1/member/card/${result.data?.id}`,
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        });
        const fullPayment = fetchPaymentResult.data?.card;
        return fullPayment
          ? {
              data: PaymentMethodMapping.apply(fullPayment),
            }
          : { error: fetchPaymentResult.error };
      },
      invalidatesTags: [TAGS.PAYMENT_METHOD],
    }),

    /**
     * Deletes an existing payment method.
     */
    deleteProcessorPaymentMethod: builder.mutation<GenericResponse, string>({
      query: (id) => {
        return {
          url: `/api/v1/member/card/delete/${id}`,
          method: 'POST',
          body: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: (result, error, arg) => [TAGS.PAYMENT_METHOD, { type: TAGS.PAYMENT_METHOD, id: arg }],
    }),

    /**
     * Sets the payment method as the
     * user's default payment method.
     */
    makePaymentMethodDefault: builder.mutation<GenericResponse, string>({
      query: (id) => {
        return {
          url: `/api/v1/member/card/set_default/${id}`,
          method: 'POST',
          body: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: (result, error, arg) => [TAGS.PAYMENT_METHOD, { type: TAGS.PAYMENT_METHOD, id: arg }],
    }),

    getProcessorPaymentMethod: builder.query<PaymentMethodType, string>({
      query: (paymentMethodId) => {
        return {
          url: `/api/v1/member/card/${paymentMethodId}`,
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { card: SourcePaymentMethodType }) => {
        if (!responseData.card || (Array.isArray(responseData?.card as any) && !(responseData?.card as any)?.length))
          return null;
        return PaymentMethodMapping.apply(responseData.card);
      },
      providesTags: (card) => {
        return card ? [{ type: TAGS.PAYMENT, id: card.id }, TAGS.PAYMENT] : [TAGS.PAYMENT];
      },
    }),

    /**
     * Retrieves the payment methods that the user
     * can use throughout the application.
     */
    getProcessorPaymentMethods: builder.query<
      {
        [PaymentOption.CreditCard]: PaymentMethodType[];
      },
      {
        usedFor: PaymentUsedFor;
        bookingData?: BookingConfigurationType;
        amount?: number;
      }
    >({
      query: () => {
        return {
          url: '/api/v1/member/card',
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { cards: SourcePaymentMethodType[] }) => {
        const batched = [];
        const mappedPaymentMethods = (responseData.cards || [])?.map((c) =>
          PaymentMethodMapping.apply(c, (a) => batched.push(a))
        );
        return {
          [PaymentOption.CreditCard]: sortPaymentMethods(mappedPaymentMethods),
        };
      },
      providesTags: (result) => {
        return result
          ? [
              ...(result?.[PaymentOption.CreditCard] || []).map(({ id }) => ({
                type: TAGS.PAYMENT_METHOD,
                id,
              })),
              TAGS.PAYMENT_METHOD,
            ]
          : [TAGS.PAYMENT_METHOD];
      },
    }),
  }),
});

export const {
  // Queries
  useGetPaymentOptionsQuery,
  useGetClientProcessorTokenQuery,
  useGetProcessorPaymentMethodsQuery,
  useGetProcessorPaymentMethodQuery,
  // Lazy
  useLazyGetPaymentOptionsQuery,
  useLazyGetClientProcessorTokenQuery,
  useLazyGetProcessorPaymentMethodsQuery,
  useLazyGetProcessorPaymentMethodQuery,
  // Mutations
  useCreateProcessorPaymentMethodMutation,
  useUpdateProcessorPaymentMethodMutation,
  useDeleteProcessorPaymentMethodMutation,
  useMakePaymentMethodDefaultMutation,
} = paymentApi;
