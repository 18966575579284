import { FC, useCallback, useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import clsx from 'clsx';

import { helpers, routes } from 'utils';
import { useForm, useLocation, usePage } from 'utils/hooks';
import { getRouteTab } from 'utils/routes';
import { ThemeProvider, themes } from 'utils/theme-context';

import { useLogoutMutation, useSignInMutation } from 'redux/apis/OG/auth';

import { SkeletonCover, useModals, useNavigate } from '@zeel-dev/zeel-ui';
import { Button, Checkbox, FieldRow, Icon, IconSvg, Input, Link, SocialIcon, Tabs } from 'components/common';
import ModalForgotPassword from 'components/modals/items/ForgotPassword';

import { useGetPageBySlugQuery } from '../../../redux/apis/Wordpress';
import { SuperMenuProps } from './SuperMenu.types';
import styles from './style.module.scss';

const SuperMenu: FC<SuperMenuProps> = ({ toggleSuperMenu, isOpen, testId }) => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const { data: wordpressData } = useGetPageBySlugQuery('hsa-fsa');
  const { learn_more_link } = (wordpressData?.elements as any)?.hsafsa || {};
  const [signIn] = useSignInMutation();
  const [logout] = useLogoutMutation();
  const { openModal } = useModals();

  const { user, isAuthenticated, isUserFetching } = usePage();

  const navigate = useNavigate();
  const form = useForm();

  const handleResize = () => {
    if (helpers.isMobileWidth()) {
      if (!isMobile) {
        setIsMobile(true);
      }
    } else {
      if (isMobile) {
        setIsMobile(false);
      }
    }
  };

  useEffect(() => {
    // setting the active tab
    const tab = getRouteTab();
    form.setValue('tab', tab);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      helpers.unfreezeScroll();
    };
  }, []);

  useEffect(() => {
    if (isOpen) helpers.freezeScroll();
    else helpers.unfreezeScroll();
  }, [isOpen]);

  const handleSignIn = useCallback(async () => {
    const email = form.getValue('email');
    const password = form.getValue('password');

    try {
      await signIn({ email, password }).unwrap();

      if (location?.pathname === '/' && !helpers.onVa()) {
        navigate(routes.index({ host: true }));
      } else {
        toggleSuperMenu();
      }
    } catch (errors: any) {
      if (errors?.authentication) {
        form.setError('password', errors.authentication);
      }
    }
  }, [form]);

  const getExtraButton = useCallback(() => {
    const activeTab = form.getValue('tab');

    // Hide button for now when on va, to prevent redirecting to default website
    if (helpers.onVa()) return null;

    switch (activeTab) {
      case 'personal': {
        return (
          <Button
            type='primary'
            className={styles.button}
            onClick={() => {
              toggleSuperMenu();
              navigate(routes.IN_HOME_MASSAGE());
            }}
          >
            Book Now
          </Button>
        );
      }
      case 'business': {
        return (
          <Button
            type='primary'
            className={styles.button}
            onClick={() => {
              toggleSuperMenu();
              navigate(routes.CHAIR_MASSAGE.BOOK());
            }}
          >
            Book an Event
          </Button>
        );
      }
      case 'provider': {
        return (
          <Button
            type='primary'
            className={styles.button}
            onClick={() => {
              toggleSuperMenu();
              navigate(routes.APPLY({ host: true }));
            }}
          >
            Apply Now
          </Button>
        );
      }
    }
  }, [form]);

  const getTabDetailBox = useCallback(() => {
    const activeTab = form.getValue('tab');
    switch (activeTab) {
      case 'personal': {
        return (
          <div className={clsx(styles.glVisibleOnMobile, styles.tabDetailBox)}>
            <Icon type='house' className={styles.boxIcon} />
            In-Home Health & Wellness On-Demand
          </div>
        );
      }
      case 'business': {
        return (
          <div className={clsx(styles.glVisibleOnMobile, styles.tabDetailBox)}>
            <IconSvg name='building' className={clsx(styles.boxIcon, styles['boxIcon-business'])} />
            Corporate Health & Wellness Solutions
          </div>
        );
      }
      case 'provider': {
        return (
          <div className={clsx(styles.glVisibleOnMobile, styles.tabDetailBox)}>
            <Icon type='hand' className={clsx(styles.boxIcon, styles['boxIcon-provider'])} />
            Deliver World-Class Wellness
          </div>
        );
      }
      case 'health': {
        return (
          <div className={clsx(styles.glVisibleOnMobile, styles.tabDetailBox)}>
            <IconSvg name='medical-cross-circle' className={clsx(styles.boxIcon, styles['boxIcon-health'])} />
            Services for Health Care Organizations
          </div>
        );
      }
    }
  }, [form]);

  // const CITIES = [
  //   { name: 'Austin', href: routes.IN_HOME_MASSAGE_AUSTIN({ host: true }) },
  //   { name: 'Chicago', href: routes.IN_HOME_MASSAGE_CHICAGO({ host: true }) },
  //   { name: 'Dallas/Fort Worth', href: routes.IN_HOME_MASSAGE_DALLAS_FORTH_WORTH({ host: true }) },
  //   { name: 'Denver', href: routes.IN_HOME_MASSAGE_DENVER({ host: true }) },
  //   { name: 'Houston', href: routes.IN_HOME_MASSAGE_HOUSTON({ host: true }) },
  //   { name: 'Los Angeles', href: routes.IN_HOME_MASSAGE_LOS_ANGELES({ host: true }) },
  //   { name: 'Miami', href: routes.IN_HOME_MASSAGE_MIAMI({ host: true }) },
  //   { name: 'New York City', href: routes.IN_HOME_MASSAGE_NYC({ host: true }) },
  //   { name: 'Phoenix', href: routes.IN_HOME_MASSAGE_PHOENIX({ host: true }) }, // is it supposed to be Portland?
  //   { name: 'San Francisco', href: routes.IN_HOME_MASSAGE_SAN_FRANCISCO({ host: true }) },
  // ];

  // const CHAIR_CITIES = [
  //   { name: 'Dallas', href: routes.CHAIR_MASSAGE_DALLAS({ host: true }) },
  //   { name: 'Denver', href: routes.CHAIR_MASSAGE_DENVER({ host: true }) },
  //   { name: 'Los Angeles', href: routes.CHAIR_MASSAGE_LOS_ANGELES({ host: true }) },
  //   { name: 'New York City', href: routes.CHAIR_MASSAGE_NYC({ host: true }) },
  //   { name: 'San Francisco', href: routes.CHAIR_MASSAGE_SAN_FRANCISCO({ host: true }) },
  // ];

  const activeTab = form.getValue('tab');

  return (
    <ThemeProvider value={themes.primary}>
      <TransitionGroup>
        {isOpen && (
          <CSSTransition classNames='superMenu' timeout={200}>
            <div id='superMenu' className={styles.superMenu} role='menu' data-testid={testId ?? `supermenu`}>
              <Icon
                id='superMenu-close'
                type='times'
                className={`${styles.close}`}
                onClick={toggleSuperMenu}
                title='close'
              />
              <div className={styles.left}>
                <Link
                  href={routes.index({ host: true })}
                  className={styles.logo}
                  title='homepage'
                  data-testid={`${testId ?? `supermenu`}--main-company-logo`}
                />
                <Tabs
                  {...form.getProps('tab')}
                  className={styles.tabs}
                  preventDefaultFirst
                  scrollable
                  items={[
                    { label: 'Individuals', value: 'personal' },
                    {
                      label: 'Businesses',
                      value: 'business',
                      activeLineClassName: styles.businessTabActiveLine,
                    },
                    {
                      label: 'Healthcare Partners',
                      value: 'health',
                      activeLineClassName: styles.healthTabActiveLine,
                    },
                    { label: 'Providers', value: 'provider' },
                  ]}
                />
                {getTabDetailBox()}
                <div className={styles.links}>
                  {(!isMobile || activeTab === 'personal') && (
                    <ul>
                      <li>
                        <h3 className={styles.menuTitle}>Individuals</h3>
                      </li>
                      <li className={styles.headerLink}>In-Home Services</li>
                      <li>
                        <Link
                          href={routes.IN_HOME_MASSAGE()}
                          className={styles.link}
                          activeClassName={styles['link--active']}
                        >
                          <IconSvg name='massage-hands' />
                          <p>Massage</p>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={routes.VA_MICROSITE({ host: true })}
                          className={styles.link}
                          activeClassName={styles['link--active']}
                        >
                          <IconSvg name='military' />
                          <p>Massage for Veterans</p>
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={routes.MASSAGES_NEAR_ME({ host: true })}
                          className={styles.link}
                          activeClassName={styles['link--active']}
                        >
                          Massage Pricing & Locations
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={routes.MEMBERSHIP.index()}
                          className={styles.link}
                          activeClassName={styles['link--active']}
                        >
                          Massage Membership
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={routes.MASSAGE_GIFT_CERTIFICATE({ host: true })}
                          className={styles.link}
                          activeClassName={styles['link--active']}
                        >
                          Zeel Gifts
                        </Link>
                      </li>
                      <li>
                        <Link
                          href={routes.ESTORE.index()}
                          className={styles.link}
                          activeClassName={styles['link--active']}
                        >
                          Zeel Store
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={styles.link}
                          activeClassName={styles['link--active']}
                          href={routes.TRUST_AND_SAFETY.index()}
                        >
                          Trust & Safety
                        </Link>
                      </li>
                      <li>
                        <Link className={styles.link} href={learn_more_link} target='_blank'>
                          HSA/FSA Payment
                        </Link>
                      </li>
                    </ul>
                  )}
                  {(!isMobile || activeTab === 'business') && (
                    <ul>
                      <li>
                        <h3 className={styles.menuTitle}>Businesses</h3>
                      </li>
                      <li className={styles.glVisibleOnDesktop}>
                        <Link
                          className={clsx(styles.link, styles['link-business'])}
                          activeClassName={styles['link-business--active']}
                          href={routes.HEALTHCARE()}
                        >
                          Solutions for Healthcare Partners
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={clsx(styles.link, styles['link-business'])}
                          activeClassName={styles['link-business--active']}
                          href={routes.CORPORATE_WELLNESS.index()}
                        >
                          Workplace Wellness
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={clsx(styles.link, styles['link-business'])}
                          activeClassName={styles['link-business--active']}
                          href={routes.CHAIR_MASSAGE.BOOK()}
                        >
                          Book a Chair Massage Event
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={clsx(styles.link, styles['link-business'])}
                          activeClassName={styles['link-business--active']}
                          href={routes.SPA({ host: true })}
                        >
                          Zeel Spa Staffing
                        </Link>
                      </li>
                      {/* <li>
                        <Link
                          className={clsx(styles.link, styles['link-business'])}
                          activeClassName={styles['link-business--active']}
                          href={routes.COVID()}>
                          COVID-19 Testing for Businesses
                        </Link>
                      </li> */}
                      {/* <li><Link className={clsx(styles.link, styles['link-business'])} activeClassName={styles['link-business--active']} href={routes.PPE()} target="_blank">PPE Sourcing</Link></li> */}

                      <li>
                        <Link
                          className={clsx(styles.link, styles['link-business'])}
                          activeClassName={styles['link-business--active']}
                          href={routes.TRUST_AND_SAFETY.BUSINESS()}
                        >
                          Trust & Safety
                        </Link>
                      </li>
                    </ul>
                  )}
                  {activeTab === 'health' && (
                    <ul className={styles.glVisibleOnMobile}>
                      <li>
                        <h3 className={styles.menuTitle}>Healthcare Partners</h3>
                      </li>
                      <li>
                        <Link
                          href={routes.HEALTHCARE()}
                          className={clsx(styles.link, styles['link-health'])}
                          activeClassName={styles['link-health--active']}
                        >
                          Solutions for Healthcare Partners
                        </Link>
                      </li>
                    </ul>
                  )}
                  {(!isMobile || activeTab === 'provider') && (
                    <ul>
                      <li>
                        <h3 className={styles.menuTitle}>Providers</h3>
                      </li>
                      <li>
                        <Link
                          className={styles.link}
                          activeClassName={styles['link--active']}
                          href={routes.PROVIDER.index()}
                        >
                          Work With Zeel
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={styles.link}
                          activeClassName={styles['link--active']}
                          href={routes.PROVIDER.APPLICATION()}
                        >
                          Apply Now
                        </Link>
                      </li>
                      <li>
                        <Link
                          className={styles.link}
                          activeClassName={styles['link--active']}
                          href={routes.TRUST_AND_SAFETY.PROVIDER()}
                        >
                          Trust & Safety
                        </Link>
                      </li>
                    </ul>
                  )}

                  <div className={clsx(styles.glVisibleOnMobile, styles.separator)} />

                  <ul className={isMobile ? styles.glVisibleOnMobile : ''}>
                    <li>
                      <h3 className={styles.menuTitle}>About</h3>
                    </li>
                    <li>
                      <Link className={styles.link} activeClassName={styles['link--active']} href={routes.ABOUT_US()}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link className={styles.link} href={routes.CAREER()}>
                        Careers
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={styles.link}
                        activeClassName={styles['link--active']}
                        href={routes.BLOG({ host: true })}
                      >
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={styles.link}
                        activeClassName={styles['link--active']}
                        href={routes.PRESS_MEDIA_NEWS()}
                      >
                        Press, Media, and News
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={styles.link}
                        activeClassName={styles['link--active']}
                        href={routes.PRESS_RELEASES.index()}
                      >
                        Press Releases
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={styles.link}
                        activeClassName={styles['link--active']}
                        href={routes.SITEMAP({ host: true })}
                      >
                        Site Map
                      </Link>
                    </li>
                    <li>
                      <Link className={styles.link} href={routes.ACCESSIBILITY()}>
                        Accessibility Statement
                      </Link>
                    </li>
                  </ul>

                  {/* <div className={isMobile ? styles.glVisibleOnMobile : ''}>
                    <h3 className={styles.menuTitle}>About</h3>
                    <ul>
                      <li>
                        <Link className={styles.link} activeClassName={styles['link--active']} href={routes.ABOUT_US()}>
                          About Us
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  {/* 
                  <div className={clsx({ [styles.glVisibleOnMobile]: isMobile }, styles.extraContent)}>
                    <h3 className={clsx(styles.menuTitle, styles.menuTitleForce)}>In-Home Massage Locations</h3>
                    <ul className={styles.localList}>
                      {CITIES.map(({ name, href }) => {
                        return (
                          <li key={name}>
                            <Link className={styles.link} href={href}>
                              {name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>

                    <h3 className={clsx(styles.menuTitle, styles.menuTitleForce)}>Chair Massage Locations</h3>
                    <ul className={styles.localList}>
                      {CHAIR_CITIES.map(({ name, href }) => {
                        return (
                          <li key={name}>
                            <Link className={styles.link} href={href}>
                              {name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div> */}
                </div>
                <div className={styles.bottom}>
                  <Link href={routes.HELP.index()} className={styles.question}>
                    <div className={styles.questionIcon}></div>
                    <span>Have a question?</span>
                  </Link>
                  <ul className={styles.socialBox} role='list'>
                    <li>
                      <SocialIcon type='twitter' />
                    </li>
                    <li>
                      <SocialIcon type='facebook' />
                    </li>
                    <li>
                      <SocialIcon type='instagram' />
                    </li>
                    <li>
                      <SocialIcon type='yelp' />
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.right}>
                {!isAuthenticated && (
                  <>
                    <div className={`${styles.glVisibleOnDesktop} ${styles.loginBox}`}>
                      <h2>Sign In</h2>
                      <div className={styles.dontHaveAccount}>
                        Don&apos;t have an account? <Link href={routes.SIGN_UP({ host: true })}>Sign up!</Link>
                      </div>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <FieldRow>
                          <Input
                            {...form.getProps('email')}
                            type='email'
                            label='Email address'
                            autoComplete='email username'
                            testId='supermenu-email-input'
                          />
                        </FieldRow>
                        <FieldRow>
                          <Input
                            {...form.getProps('password')}
                            type='password'
                            label='Password'
                            autoComplete='current-password'
                            testId='supermenu-password-input'
                          />
                        </FieldRow>
                        <FieldRow alignY='center'>
                          <Checkbox
                            {...form.getProps('remember')}
                            required={false}
                            label='Stay Signed In'
                            testId='supermenu-remember-me-checkbox'
                          />
                          <div
                            className={`${styles.forgotPassword}`}
                            onClick={() => openModal({ element: <ModalForgotPassword /> })}
                            data-testid={`${testId ?? `supermenu`}--forgot-password-link`}
                          >
                            Forgot/Create password?
                          </div>
                        </FieldRow>
                        <Button
                          className={styles.submitButton}
                          disabled={!form.isValid()}
                          type='primary'
                          onClick={handleSignIn}
                          testId='supermenu-log-in-button'
                        >
                          Sign in
                        </Button>
                      </form>
                    </div>

                    <div className={styles.glVisibleOnMobile}>
                      <FieldRow wrapMobile={false} marginY={false}>
                        <Button
                          type='secondary'
                          className={styles.button}
                          onClick={() => navigate(`${routes.SIGN_IN({ host: true })}?referrer=${window.location.href}`)}
                          testId='supermenu-log-in-button'
                        >
                          Sign in
                        </Button>
                        {getExtraButton()}
                      </FieldRow>
                    </div>
                  </>
                )}

                {isAuthenticated && (
                  <>
                    <SkeletonCover loading={isUserFetching}>
                      <h2>{user?.firstName ? `Hi ${user?.firstName}!` : `Hi!`}</h2>
                    </SkeletonCover>
                    <div className={clsx(styles.glVisibleOnMobile, styles.buttons)}>
                      <FieldRow wrapMobile={false} marginY={false}>
                        <Button
                          className={styles.button}
                          type='secondary'
                          onClick={() => {
                            logout();
                            toggleSuperMenu();
                          }}
                          testId='supermenu-log-out-button'
                        >
                          Sign Out
                        </Button>
                        {getExtraButton()}
                      </FieldRow>
                    </div>
                    <div className={clsx(styles.glVisibleOnDesktop, styles.buttons)}>
                      {getExtraButton()}
                      <Button
                        className={styles.button}
                        type='secondary'
                        onClick={() => {
                          logout();
                          toggleSuperMenu();
                        }}
                        testId='supermenu-log-out-button'
                      >
                        Sign Out
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </ThemeProvider>
  );
};

export default SuperMenu;
