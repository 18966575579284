import { FC, ReactNode, useCallback, useContext, useLayoutEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import { helpers } from 'utils';
import { ThemeContext } from 'utils/theme-context';

import { SkeletonProvider } from '@zeel-dev/zeel-ui';
import AppHead from 'components/AppHead';
import { Icon, Loader } from 'components/common';

import useUi from '../../../utils/hooks/useUi';
import Footer, { FooterProps } from '../Footer';
import Navigation, { NavigationProps } from '../Navigation';
import styles from './style.module.scss';

export type BaseLayoutProps = {
  className?: string;
  contentClassName?: string;
  footer?: string | boolean | null;
  children?: ReactNode;
  showLoader?: boolean;
  loading?: boolean;
  overlayLoading?: boolean;
  partialLoading?: boolean;
  loadingError?: { message: string };
  tags?: Record<string, any>;
  elasticCover?: boolean;
  errorMessage?: string;
  footerProps?: FooterProps;
  navProps?: NavigationProps;
  theme?: string;
  skeleton?: boolean;
  testId?: string;
};

const BaseLayout: FC<BaseLayoutProps> = ({
  className = '',
  contentClassName = '',
  errorMessage = null,
  footer,
  children,
  showLoader = true,
  loading = false,
  partialLoading = false,
  overlayLoading = false,
  loadingError = null,
  tags = {},
  footerProps,
  navProps = {},
  theme: propTheme,
  skeleton,
  testId,
}) => {
  const { fromMobile } = useUi();
  const [reachedEnd, setReachedEnd] = useState<boolean>(false);
  const contentRef = useRef<any>();
  const headerRef = useRef<any>();
  const themeContext = useContext(ThemeContext);
  const theme = propTheme || themeContext;

  const calculateContentHeight = useCallback(() => {
    const headerHeight = headerRef.current?.clientHeight || 0;
    const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    if (contentRef.current) {
      contentRef.current.style.minHeight = vh - headerHeight;
    }
  }, []);

  const onScroll = useCallback(() => {
    const safetyZone = 250;
    const d: any = document.documentElement;
    const _reachedEnd = d.getBoundingClientRect().bottom < window.innerHeight + safetyZone;

    if (_reachedEnd !== reachedEnd) {
      setReachedEnd(_reachedEnd);
    }
  }, [reachedEnd]);

  const onResize = useCallback(() => {
    calculateContentHeight();
  }, []);

  useLayoutEffect(() => {
    onResize();
    window.addEventListener('scroll', onScroll);
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('scroll', onScroll);
      window.removeEventListener('resize', onResize);
    };
  }, []);

  // if (overlayLoading) return <LoadingOverlay visible={true} />;

  return (
    <>
      <AppHead tags={tags} />
      <div
        id='layout'
        className={clsx(styles.layout, className, styles[`theme-${theme}`])}
        data-testid={testId ?? `base-layout`}
      >
        {showLoader && (
          <Loader loading={loading || partialLoading} data-testid={`${testId ?? `base-layout`}--loader`} />
        )}
        {!fromMobile && (
          <Navigation
            onHeaderRef={(ref) => {
              headerRef.current = ref;
              onResize();
            }}
            {...navProps}
          />
        )}
        {errorMessage && (
          <div
            className={clsx(styles.errorBox, { [styles['errorBox--withProgressBar']]: !!navProps?.progressBar })}
            data-testid={`${testId ?? `base-layout`}--error-message-div`}
          >
            {errorMessage}
          </div>
        )}
        <SkeletonProvider loading={skeleton}>
          <div
            id='content'
            ref={(r) => (contentRef.current = r)}
            className={clsx(styles.content, { [styles.loading]: loading }, contentClassName)}
          >
            {overlayLoading && (
              <Icon
                type='fa-circle-o-notch fa-spin'
                className={styles.loader}
                data-testid={`${testId ?? `base-layout`}--overlay-loading-icon`}
              />
            )}
            {partialLoading && (
              <div className={styles.partialLoader}>
                <Icon
                  type='fa-circle-o-notch fa-spin'
                  hotspot={false}
                  className={styles.loadingIcon}
                  data-testid={`${testId ?? `base-layout`}--partial-loading-icon`}
                />
              </div>
            )}
            {!loading && loadingError && (
              <div className={styles.glContainer}>
                <div className={styles.error} data-testid={`${testId ?? `base-layout`}--loading-error-div`}>
                  {helpers.onLowerEnvironment() ? loadingError?.message : 'There was an error. Please refresh the page'}
                </div>
              </div>
            )}
            {!overlayLoading && children}
          </div>

          {!fromMobile && (footer === undefined || footer) && <Footer {...footerProps} />}
        </SkeletonProvider>
      </div>
    </>
  );
};

export default BaseLayout;
